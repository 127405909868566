export interface IGarden {
    name: string;
    shortCode: string;
    code: string;
    area: string;
    level: number;
    type: string;
    typeCode: string;
    street: string;
    streetCode: string;
    telphone: string;
    address: string;
    ownership: string;
    ownershipCode: string;
    language: string;
    languageCode: string;
    remark: string;
    isParcel: number;
    parentID: number;
    isDelete: number;
    isRegisterSite: number;
    isDisplay: number;
    isMbZS: number;
    benshiSort: number;
    kindergarten: string;
    iStbzs: boolean;
    iSxbzs: boolean;
    fees: string;
    admissionNotes: string;
    enrollScope: string;
    id: number;
    showBtn: boolean;
    regRemark: string;
}

export class Garden implements IGarden {
    name = '';
    shortCode = '';
    code = '';
    area = '';
    level = 0;
    type = '';
    typeCode = '';
    street = '';
    streetCode = '';
    telphone = '';
    address = '';
    ownership = '';
    ownershipCode = '';
    language = '';
    languageCode = '';
    remark = '';
    isParcel = 0;
    parentID = 0;
    isDelete = 0;
    isRegisterSite = 0;
    isDisplay = 0;
    isMbZS = 0;
    benshiSort = 0;
    kindergarten = '';
    iStbzs = false;
    iSxbzs = false;
    fees = '';
    admissionNotes = '';
    enrollScope = '';
    id = 0;
    showBtn = true
    regRemark = ''
}

export interface IGetZSGardenListInput {
    gradeCode: string;
    type: string;
}

export class getZSGardenListInput implements IGetZSGardenListInput {
    gradeCode = "1";
    type = "1";
}