import axiosHttpService from '@/core/utils/axios';
import { IGarden, getZSGardenListInput } from './dtos/garden'


// type getZSGardenListType = 1 | 2;

export class GardenService {

    /*
    * 查询园所列表
    * gradeCode:1:小班园所（不传默认小班）2：托班园所
    * */
    getZSGardenList(input: getZSGardenListInput) {
        return axiosHttpService.get<Array<IGarden>>({
            url: 'Garden/GetZSGardenList',
            params: input
        });
    }

    getById(id: string) {
        return axiosHttpService.get<IGarden>({
            url: 'Garden/GetById',
            params: { id }
        });
    }
}

export default new GardenService();