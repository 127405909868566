import storage from "../storage";


export const notThisCity_stepsList = [
    "01 父母持证信息", "02 幼儿基础信息", "03 家庭成员信息", "04 验证材料上传", "05 报名园所", "06 报名确认"
];

export const notThisCity_stepsList2 = [
    "01 父母持证信息", "02 幼儿基础信息", "03 家庭成员信息", "04 打分表", "05 验证材料上传", "06 报名园所", "07 报名确认"
];

// export function getStepList(isHoldResidencePermit: number, isApplyScore?: number | null, isStandardScore?: number | null) {
//     if (
//         isHoldResidencePermit == 3 ||
//         (isHoldResidencePermit == 1 &&
//             isApplyScore == 1 &&
//             isStandardScore == 1)
//     ) {
//         return notThisCity_stepsList;
//     } else {
//         return notThisCity_stepsList2;
//     }
// }

export function getStepList() {
    const parentInfo = storage.GET_ParentInfomation();
    if (parentInfo.parentsAllLocal == 0 && (
        parentInfo.isHoldResidencePermit == 3 ||
        (parentInfo.isHoldResidencePermit == 1 &&
            parentInfo.isApplyScore == 1 &&
            parentInfo.isStandardScore == 1))
    ) {
        return notThisCity_stepsList;
    } else {
        return notThisCity_stepsList2;
    }
}

